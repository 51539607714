import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import Tile from '../components/tile'

const PhotographyTagTemplate = ({ data }) => (
  <div>
    <h1 className="text-3xl font-semibold mb-8">{data.tag.title}</h1>
    <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
      <SEO
        title={data.tag.title}
        description={data.tag.description} />
      {data.photos.edges.map(({ node }) => {
        return (
          <Tile
            key={node.id}
            to={`/photography/${node.fields.slug}`}
            fluid={node.image.localFile.childImageSharp.fluid}
          />
        )
      })}
    </div>
  </div>
)

export default PhotographyTagTemplate

export const query = graphql`
  query PhotographyTagPageQuery ($id: String) {
    photos: allContentfulPhoto(filter: {tags: {elemMatch: {id: {eq: $id}}}}) {
      edges {
        node {
          title
          fields {
            slug
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 500, cropFocus: ENTROPY) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    tag: contentfulTag(id: {eq: $id}) {
      id
      title
    }
  }
`
